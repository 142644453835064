import {
  BankOutlined,
  BarChartOutlined,
  ExclamationCircleOutlined,
  FileImageOutlined,
  FileOutlined,
  PaperClipOutlined,
  OrderedListOutlined,
  KeyOutlined,
  NotificationOutlined,
  SafetyOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";

export const PATHS = {
  home: "/",
  listRole: "/list-role",
  listRoleDetail(id: string) {
    return `${this?.listRole}/${id}`;
  },
  listAdminUser: "/list-admin-user",
  listAdminUserDetail(id: string) {
    return `${this?.listAdminUser}/${id}`;
  },
  listUser: "/list-user",
  listUserDetail(id: string) {
    return `${this?.listUser}/${id}`;
  },
  listDebt: "/list-debt",
  listOrder: "/list-order",
  listOrderDetail(id: string) {
    return `${this?.listOrder}/${id}`;
  },
  listBranch: "/list-branch",
  listBranchDetail(id: string) {
    return `${this?.listBranch}/${id}`;
  },
  listCategoryProduct: "/list-category-product",
  listCategoryProductDetail(id: string) {
    return `${this?.listCategoryProduct}/${id}`;
  },
  listProduct: "/list-product",
  listProductDetail(id: string) {
    return `${this?.listProduct}/${id}`;
  },
  listVehicle: "/list-vehicle",
  listVehicleDetail(id: string) {
    return `${this?.listVehicle}/${id}`;
  },
  listNews: "/list-news",
  listNewsDetail(id: string) {
    return `${this?.listNews}/${id}`;
  },
  listBank: "/list-bank",
  listBankDetail(id: string) {
    return `${this?.listBank}/${id}`;
  },
  listKeywordSearch: "/list-keyword-search",
  login: "/login",
  listBanner: "/list-banner",
  listBannerDetail(id: string) {
    return `${this?.listBanner}/${id}`;
  },
  listKeyword: "/list-keyword",
  listKeywordDetail(id: string) {
    return `${this?.listKeyword}/${id}`;
  },
  listActivity: "/activity",
  listNotification: "/list-notification",
  listGuaranteeDetail: "/guarantee-detail",
  listDebtDetail: "/debt-detail",
  listUserRequest: "/list-user-request",
};

export const ROUTES = [
  {
    title: "Sản phẩm BDS",
    href: PATHS.listProduct,
    icon: ShoppingOutlined,
    page: "productHouse/list",
    role: "SUPER_ADMIN",
  },
  {
    title: "Sản phẩm BDS chi tiết",
    href: PATHS.listProductDetail(":id"),
    icon: FileOutlined,
    page: "productHouse/detail",
    hideMenu: true,
    role: "SUPER_ADMIN",
  },
  {
    title: "Sản phẩm xe",
    href: PATHS.listVehicle,
    icon: ShoppingOutlined,
    page: "productVehicle/list",
    role: "SUPER_ADMIN",
  },
  {
    title: "Sản phẩm xe chi tiết",
    href: PATHS.listVehicleDetail(":id"),
    icon: FileOutlined,
    page: "productVehicle/detail",
    hideMenu: true,
    role: "SUPER_ADMIN",
  },
  {
    title: "Tin tức",
    href: PATHS.listNews,
    icon: PaperClipOutlined,
    page: "news/list",
    role: "SUPER_ADMIN",
  },
  {
    title: "Order",
    href: PATHS.listOrder,
    icon: OrderedListOutlined,
    page: "order/list",
    role: "SUPER_ADMIN",
  },
  {
    title: "Order Detail",
    href: PATHS.listOrderDetail(":id"),
    icon: PaperClipOutlined,
    page: "order/detail",
    hideMenu: true,
    role: "SUPER_ADMIN",
  },
  {
    title: "Danh sách tài khoản",
    href: PATHS.listAdminUser,
    icon: OrderedListOutlined,
    page: "adminUser/list",
    role: "SUPER_ADMIN",
  },
  {
    title: "Account Detail",
    href: PATHS.listAdminUserDetail(":id"),
    icon: PaperClipOutlined,
    page: "adminUser/detail",
    hideMenu: true,
    role: "SUPER_ADMIN",
  },
  {
    title: "Tin tức chi tiết",
    href: PATHS.listNewsDetail(":id"),
    icon: FileOutlined,
    page: "news/detail",
    hideMenu: true,
    role: "news.get_detail",
  },
  {
    title: "Ngân hàng",
    href: PATHS.listBank,
    icon: BankOutlined,
    page: "bank/list",
    role: "bank_info.get_list",
  },
  {
    title: "Ngân hàng chi tiết",
    href: PATHS.listBankDetail(":id"),
    icon: FileOutlined,
    page: "bank/detail",
    hideMenu: true,
    role: "bank_info.get_detail",
  },
  {
    title: "Banner",
    href: PATHS.listBanner,
    icon: FileImageOutlined,
    page: "banner/list",
    role: "banner.get_list",
  },
  {
    title: "Banner chi tiết",
    href: PATHS.listBannerDetail(":id"),
    icon: FileOutlined,
    page: "banner/detail",
    hideMenu: true,
    role: "banner.get_detail",
  },
  {
    title: "Keyword",
    href: PATHS.listKeyword,
    icon: KeyOutlined,
    page: "keyword/list",
    role: "keyword.get_list",
  },
  {
    title: "Keyword chi tiết",
    href: PATHS.listKeywordDetail(":id"),
    icon: FileOutlined,
    page: "keyword/detail",
    hideMenu: true,
    role: "keyword.get_detail",
  },
  {
    title: "Hoạt động",
    href: PATHS.listActivity,
    icon: BarChartOutlined,
    page: "activity/list",
    role: "admin.get_list_log",
  },
  {
    title: "Thông báo",
    href: PATHS.listNotification,
    icon: NotificationOutlined,
    page: "notification/list",
    role: "notification.get_list",
  },
  {
    title: "Nội dung bảo hành",
    href: PATHS.listGuaranteeDetail,
    icon: SafetyOutlined,
    page: "guarantee/detail",
    role: "guarantee.get_detail",
  },
  {
    title: "Nội dung công nợ",
    href: PATHS.listDebtDetail,
    icon: SafetyOutlined,
    page: "contentDebt/detail",
    role: "contentDebt_get_detail",
  },
  {
    title: "Yêu cầu",
    href: PATHS.listUserRequest,
    icon: ExclamationCircleOutlined,
    page: "userRequest/list",
    role: "user_request.get_list",
  },
];
